import { Col, Container, Row } from 'react-bootstrap'

import Brands from '../components/Brands'
import Discover from '../components/Discover'
import Finance from '../assets/images/finance.svg'
import Footprint from '../assets/images/footprint.svg'
import MetaTags from 'react-meta-tags'
import Peace from '../assets/images/peace.svg'
import PricingProducts from '../components/PricingProducts'
import React from 'react'
import Return from '../assets/images/return.svg'
import Savings from '../assets/images/savings.svg'
import ScrollAnimation from 'react-animate-on-scroll'

const SolarPvPackages = () => {
  return (
    <div>
      <MetaTags>
        <title>
          Solar PV Packages | Sundrop Solar | Solar Panel Retailer and Installer
        </title>
        <meta
          name='description'
          content='One of the leading solar and energy providers in Australia. Specialize in roof mount solar system solutions, having completed more than 7 years of work experience.'
        />
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />
        <link
          rel='canonical'
          href='https://sundropsolar.com.au/solar-pv-packages'
        />
      </MetaTags>
      <section id='breadcrumbs' class='breadcrumbs'>
        <div class='container'>
          <h1>Solar PV Packages</h1>
          <ul>
            <li>
              <a href='./'>Home</a>
            </li>
            <li>Solar PV Packages</li>
          </ul>
        </div>
      </section>
      <section id='revolution'>
        <Container>
          <ScrollAnimation animateIn='fadeInUp'>
            <h2 className='text-center'>
              <span>Benefits</span> of Installing Solar
            </h2>
          </ScrollAnimation>
          <Row>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='benefit'>
                  <img
                    src={Return}
                    alt='Solar Panel Benefits'
                    className='img-fluid'
                  />
                  <h3>Great Return On Investment</h3>
                  <p>
                    The ongoing high solar rebates (which is reducing every
                    year!) and high feed-in-tariffs mean switching to solar now
                    is super affordable. With 25-year solar panel performance
                    Warranty makes going solar a no-brainer.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='benefit'>
                  <img
                    src={Footprint}
                    alt='Solar Panel Benefits'
                    className='img-fluid'
                  />
                  <h3>Reduce Your Carbon Footprint</h3>
                  <p>
                    Going solar not only helps you save more on your energy
                    bills but, it also helps you to play your part to save the
                    environment, which is deteriorating at an unprecedented
                    rate. A 6.6KW solar system allows you to reduce a whopping
                    10.6 tonnes of CO2 Every Year!
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='benefit'>
                  <img
                    src={Savings}
                    alt='Solar Panel Benefits'
                    className='img-fluid'
                  />
                  <h3>Huge Savings On Energy Bills</h3>
                  <p>
                    It is no secret that installing a solar system on your roof
                    extensively reduces your energy bills. however, the reality
                    is astonishing. some households reported that they have
                    slashed bills by upto 90% annually.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='benefit'>
                  <img
                    src={Finance}
                    alt='Solar Panel Benefits'
                    className='img-fluid'
                  />
                  <h3>Interest-Free Finance</h3>
                  <p>
                    Plenty of interest-free finance options are now available,
                    which requires zero upfront payment and thus making most
                    household’s solar dream a reality. so now instead of paying
                    the high electricity bills divert your funds to solar loan
                    repayments and after a few years enjoy your free sun’s
                    energy.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={4}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='benefit'>
                  <img
                    src={Peace}
                    alt='Solar Panel Benefits'
                    className='img-fluid'
                  />
                  <h3>Peace Of Mind</h3>
                  <p>
                    Complete peace of mind by installing high quality,
                    established and recognised solar pv products that are also
                    approved by clean energy council. solar pv systems also
                    adhere to high safety and each installation complies with
                    industry best practise guildelines as per australian
                    standard.
                  </p>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      <PricingProducts />
      <section>
        <Discover />
      </section>
      <Brands />
    </div>
  )
}

export default SolarPvPackages
