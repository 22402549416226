import { Col, Container, Row } from 'react-bootstrap'

import Bg from '../assets/images/bg-2.svg'
import Clean from '../assets/images/clean-energy.svg'
import Clients from '../components/Clients'
import Discover from '../components/Discover'
import Easy from '../assets/images/easy-installation.svg'
import Installation from '../assets/images/solar-panel-installation.png'
import Low from '../assets/images/low-maintenance-cost.svg'
import MetaTags from 'react-meta-tags'
import PricingProducts from '../components/PricingProducts'
import React from 'react'
import Retailing from '../assets/images/solar-panel-retailing.png'
import ScrollAnimation from 'react-animate-on-scroll'
import SliderArea from '../components/SliderArea'
import WhyUs from '../assets/images/why-choose-us.png'

const Homepage = () => {
  return (
    <div>
      <MetaTags>
        <title>Sundrop Solar | Solar Panel Retailer and Installer</title>
        <meta
          name='description'
          content='One of the leading solar and energy providers in Australia. Specialize in roof mount solar system solutions, having completed more than 7 years of work experience.'
        />
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />
        <link rel='canonical' href='https://sundropsolar.com.au/' />
      </MetaTags>
      <SliderArea />
      <section>
        <Container>
          <ScrollAnimation animateIn='fadeInUp'>
            <div className='text-center'>
              <h2>
                Our <span>Services</span>
              </h2>
              <p>
                We offer end to end EPC services to install solar systems with
                the use of only high precision products and components from
                globally recognized technology providers. Sundrop Solar has
                gained considerable reputation as an active contributor in
                shaping the solar revolution by successfully delivering projects
                across various segments. Sundrop Solar offer end to end Rooftop
                solar EPC services at your home to install solar rooftop system
                with optimum plant performance and you can get immediate savings
                on power bills. We offer hassle free documentation and approvals
                for net-metering at No Extra Cost.
              </p>
            </div>
          </ScrollAnimation>
          <Row className='justify-content-md-center'>
            <Col md={5}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='service'>
                  <img
                    src={Installation}
                    alt='Solar Panel Installation'
                    className='img-fluid'
                  />
                  <div className='content'>
                    <h3>Solar Panel Installation</h3>
                    <p>
                      Sundrop Solar is known for introducing the conceptual
                      solutions with regard to solar panel in residential
                      segment, Solar Panels for Buildings help roof top to
                      generate good amount of electricity and reduce energy
                      bills to great extent. These solar panels are available in
                      desired power capacity that complies with your application
                      need and budget. Proper installation of a solar panel is
                      significant to capturing the maximum amount of sunlight.
                      The angle of the panel and the amount of light hitting it
                      are both important factors that will help you maximize
                      efficiency.
                    </p>
                    {/* <Link to="#!" className="btn-2 btn-service"><span><ArrowForwardIcon /></span></Link> */}
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={5}>
              <ScrollAnimation animateIn='fadeInUp'>
                <div className='service'>
                  <img
                    src={Retailing}
                    alt='Solar Panel Retailing'
                    className='img-fluid'
                  />
                  <div className='content'>
                    <h3>Solar Panel Retailing</h3>
                    <p>
                      Customer want peace of mind that they are purchasing from
                      a retailer who is committed to a high level of service and
                      industry best practice. The Approved Solar Retailer
                      program is increasingly becoming the starting point for
                      consumers to find a retailer they can trust. These solar
                      panels are certified to operate under harsh condition and
                      tested on the several parameters to ensure high
                      performance all through the life span with minimal
                      maintenance. Professionals install solar panels at the
                      perfect angle to ensure maximum electricity generation.
                    </p>
                    {/* <Link to="#!" className="btn-2 btn-service"><span><ArrowForwardIcon /></span></Link> */}
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      <PricingProducts />
      <section>
        <div className='section-bg' id='whyus'>
          <Container>
            <Row>
              <Col md={6}>
                <ScrollAnimation animateIn='fadeInUp'>
                  <img
                    src={WhyUs}
                    alt='Why Choose Us'
                    className='img-fluid whyus'
                  />
                  <img src={Bg} alt='Design' className='img-fluid whyus-2' />
                </ScrollAnimation>
              </Col>
              <Col md={6} className='content my-auto'>
                <ScrollAnimation animateIn='fadeInUp'>
                  <h2 className='type-3'>
                    Why <span>Choose Us</span>
                  </h2>
                  <p>
                    After gaining several years of experience as a solar panel
                    installer. We are proud to be continued our service to
                    Victorians as a solar retailer…..Or we are solar retailers
                    plus installers compare to other companies in the market.
                    They are only retailers. To put this into consideration. You
                    will be looked after very well after installation or faults.
                  </p>
                </ScrollAnimation>
                <ScrollAnimation animateIn='fadeInUp'>
                  <Row className='benefits'>
                    <Col xs={4} md={4}>
                      <img
                        src={Clean}
                        alt='Clean Energy'
                        className='img-fluid'
                      />
                      <h3>Clean Energy</h3>
                    </Col>
                    <Col xs={4} md={4}>
                      <img
                        src={Easy}
                        alt='Easy Installation'
                        className='img-fluid'
                      />
                      <h3>Easy Installation</h3>
                    </Col>
                    <Col xs={4} md={4}>
                      <img
                        src={Low}
                        alt='Low Maintenance Cost'
                        className='img-fluid'
                      />
                      <h3>Low Maintenance Cost</h3>
                    </Col>
                  </Row>
                </ScrollAnimation>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Discover />
      <div className='cmt-1'>
        <Clients />
      </div>
    </div>
  )
}

export default Homepage
