import 'react-phone-input-2/lib/style.css'

import { Alert, Col, Container, Form, Row } from 'react-bootstrap'
import React, { useEffect, useState } from 'react'

import { FORM_URL } from '../config'
import { LinearProgress } from '@mui/material'
import MetaTags from 'react-meta-tags'
import PhoneInput from 'react-phone-input-2'
import ScrollAnimation from 'react-animate-on-scroll'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import isEmail from 'validator/lib/isEmail'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { useHistory } from 'react-router-dom'

const Support = () => {
  const to_email = 'info@sundropsolar.com.au'
  const cc = 'arkayappsseo@gmail.com'
  const title = 'Website Inquiry'
  const subject = 'Contact Form - Sundrop Solar'
  let history = useHistory()
  useEffect(() => {
    window.scrollTo(0, 0)
    // eslint-disable-next-line
  }, [])
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const [name, setName] = useState(null)
  const [email, setEmail] = useState(null)
  const [orderNo, setOrderNo] = useState(null)
  const [query, setQuery] = useState(null)
  const [number, setNumber] = useState(null)
  const [address, setAddress] = useState(null)
  const [queryDetails, setQueryDetails] = useState(null)
  const template = `<!DOCTYPE html>
    <html lang="en">
    <head>
        <meta charset="UTF-8">
        <meta http-equiv="X-UA-Compatible" content="IE=edge">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <meta
          name='keywords'
          content='sundrop solar , sundrop solar australia reviews , sundrop solar australia , sundrop solar reviews , solar installers melton , solar installers melbourne , solar installation tarneit , solar installers hoppers crossing , solar installers aintree , solar installers near me'
        />
        <title>Sundrop Solar</title>
        <style>
            body {
                line-height: 1.4;
                font-family: sans-serif;
                background-color: #f6f6f6;
            }
            p {
                margin: 0;
                margin-bottom: 15px;
                font-size: 14px;
                font-weight: normal;
                font-family: sans-serif;
            }
            hr {
                border: 0;
                border-bottom: 1px solid #b9b9b9;
                margin: 10px 0;
            }
            .logo {
                margin-top: 1rem;
                margin-bottom: 1rem;
                width:180px;
              
            }
            .text-center {
                text-align: center !important;
            }
            .content {
                Margin: 0 auto;
                display: block;
                max-width: 580px;
                padding: 0 10px 0 10px;
                box-sizing: border-box;
            }
            .innerwrap {
                box-sizing: border-box;
                padding: 20px 20px 10px 20px;
                background: #f2f2f2;
            }
            .card-header {
                background:#0061C3;
                color: #fff;
                text-align: center;
                padding: 20px;
                font-size: 16px;
            }
            .card-header p {
                color: #fff;
                text-align: center;
                font-size: 16px;
                margin-bottom: unset;
            }
            .card-body {
                background: #ffffff;
                border-radius: 3px;
                width: 100%;
                box-sizing: border-box;
                padding: 20px;
                border-bottom: 3px solid #0061C3;
            }
            .card-body p strong {
                color: #333;
            }
            .card-body p strong.heading {
                color:#2A2765;
            }
            .copyright {
                color: #999999;
                font-size: 12px;
                text-align: center;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }
        </style>
    </head>
    <body>
        <div class="content">
            <div class="text-center">
                <img src="https://sundropsolar.com.au/sundropsolar-logo.png" alt="logo" class="logo">
            </div>
            <div class="card-header">
                <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
                <p>You Received an Inquiry !</p>
            </div>
            <div class="card-body">
                <p><strong>Name : </strong>${name}</p>
                <p><strong>Order No : </strong>${orderNo}</p>
                <p><strong>Query : </strong>${query}</p>
                <p><strong>Query Details : </strong>${queryDetails}</p>
                <hr>
                <p><strong class="heading">Contact Information :</strong></p>
                <div class="innerwrap">
                    <p><strong>Mobile No: </strong>${number}</p>
                    <p><strong>Email: </strong>${email}</p>
                    <p><strong>Address: </strong>${address}</p>
                </div>
            </div>
            <div class="copyright">
                <p>&copy; 2023 Sundrop Solar</p>
            </div>
        </div>
    </body>
    </html>`

  const progress = loading ? <LinearProgress /> : ''

  const mailError = <p>{error}</p>

  const handleName = (e) => {
    const value = e.target.value
    setName(value)
    setError(null)
  }
  const handleEmail = (e) => {
    var value = e.target.value
    value = ltrim(rtrim(value))
    setEmail(value)
    if (!isEmail(value)) {
      setError(<Alert variant={'danger'}>Please enter a valid email</Alert>)
      setDisabled(true)
    } else {
      setError(null)
      setDisabled(false)
    }
  }
  const handleOrderNo = (e) => {
    const value = e.target.value
    setOrderNo(value)
    setError(null)
  }
  const handleQuery = (e) => {
    const value = e.target.value
    setQuery(value)
    setError(null)
  }
  const handleMobile = (value) => {
    setNumber(value)
    setError(null)
  }
  const handleAddress = (e) => {
    const value = e.target.value
    setAddress(value)
    setError(null)
  }
  const handleQueryDetails = (e) => {
    const value = e.target.value
    setQueryDetails(value)
    setError(null)
  }

  const handleFormSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    if (name === null) {
      setError(<Alert variant={'danger'}>Full Name Required</Alert>)
      setLoading(false)
    } else if (orderNo === null) {
      setError(<Alert variant={'danger'}>Order No. Required</Alert>)
      setLoading(false)
    } else if (query === null) {
      setError(<Alert variant={'danger'}>Query is Required</Alert>)
      setLoading(false)
    } else if (number === null) {
      setError(<Alert variant={'danger'}>Contact Number Required</Alert>)
      setLoading(false)
    } else if (address === null) {
      setError(<Alert variant={'danger'}>Address is Required</Alert>)
      setLoading(false)
    } else if (queryDetails === null) {
      setError(<Alert variant={'danger'}>Query Details Required</Alert>)
      setLoading(false)
    } else {
      setDisabled(true)
      const data = {
        title: title,
        to_email: to_email,
        cc: cc,
        template: template,
        subject: subject,
      }
      const headerConfig = {
        headers: {
          accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
      const url = 'https://api.sendmail.adhyaynam.in/send-mail'
      axios
        .post(url, data, headerConfig)
        .then((response) => {
          document.getElementById('supportForm').reset()
          setDisabled(false)
          setLoading(false)
          history.push('/thank-you')
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response)
            setError(
              <Alert variant={'danger'}>
                Something went wront. Please try again later
              </Alert>
            )
          } else if (error.request) {
            console.log(error.request)
            setError(
              <Alert variant={'danger'}>
                Something went wront. Please try again later
              </Alert>
            )
          } else {
            console.log(error)
            setError(
              <Alert variant={'danger'}>
                Something went wront. Please try again later
              </Alert>
            )
          }
          setDisabled(false)
          setLoading(false)
        })
    }
  }
  return (
    <div>
      <MetaTags>
        <title>
          Support | Sundrop Solar | Solar Panel Retailer and Installer
        </title>
        <meta
          name='description'
          content='One of the leading solar and energy providers in Australia. Specialize in roof mount solar system solutions, having completed more than 7 years of work experience.'
        />
        <link rel='canonical' href='https://sundropsolar.com.au/support' />
      </MetaTags>
      <section id='breadcrumbs' class='breadcrumbs'>
        <div class='container'>
          <h1>Support</h1>
          <ul>
            <li>
              <a href='./'>Home</a>
            </li>
            <li>Support</li>
          </ul>
        </div>
      </section>
      <section>
        <Container>
          {progress}
          <ScrollAnimation animateIn='fadeInUp'>
            <Form className='form' id='supportForm' onSubmit={handleFormSubmit}>
              <div className='text-center'>
                <h2>
                  Our <span>Support Staff</span> Will Reach You Very Soon
                </h2>
              </div>
              <Row className='justify-content-md-center'>
                <Col md={6}>
                  <TextField
                    className='form-control'
                    label='Full Name'
                    size='small'
                    variant='outlined'
                    required
                    inputProps={{ minLength: 3, maxLength: 100 }}
                    onChange={handleName}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    className='form-control'
                    label='Email'
                    size='small'
                    variant='outlined'
                    type='email'
                    inputProps={{ maxLength: 100 }}
                    onChange={handleEmail}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    className='form-control'
                    label='Order No. / Quote No.'
                    size='small'
                    variant='outlined'
                    required
                    inputProps={{ minLength: 1, maxLength: 50 }}
                    onChange={handleOrderNo}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    className='form-control'
                    label='Specify Your Query'
                    size='small'
                    variant='outlined'
                    required
                    inputProps={{ minLength: 2, maxLength: 50 }}
                    onChange={handleQuery}
                  />
                </Col>
                <Col md={6}>
                  <PhoneInput
                    country={'au'}
                    onChange={handleMobile}
                    inputProps={{ minLength: 3, maxLength: 18, required: true }}
                  />
                </Col>
                <Col md={6}>
                  <TextField
                    className='form-control'
                    label='Address / Location'
                    size='small'
                    variant='outlined'
                    required
                    inputProps={{ minLength: 2, maxLength: 200 }}
                    onChange={handleAddress}
                  />
                </Col>
                <Col md={12}>
                  <TextField
                    multiline
                    rows={4}
                    className='form-control'
                    size='small'
                    label='Specify Your Query In Detail'
                    variant='outlined'
                    required
                    inputProps={{ minLength: 3, maxLength: 200 }}
                    onChange={handleQueryDetails}
                  />
                </Col>
                {mailError}
                <Col md={4}>
                  <button className='w-100' type='submit' disabled={disabled}>
                    SEND MESSAGE
                  </button>
                </Col>
              </Row>
            </Form>
          </ScrollAnimation>
          {progress}
        </Container>
      </section>
    </div>
  )
}

export default Support
